import React from "react";
import { motion } from "framer-motion";
import Item from "../components/card/items";
import Tenthousand from "../assets/one.jpg";
import EightyThousand from "../assets/two.jpg";
import HundredThousand from "../assets/three.jpg";
import TwoFiftyThousnad from "../assets/four.jpg";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";

function Home() {
  const data = [
    {
      id: 1,
      name: "Entry",
      image: Tenthousand,
      price: 0,
      isSoldOut: true,
    },
    {
      id: 2,
      name: "Blue Flame",
      image: EightyThousand,
      price: 10000,
      isSoldOut: false,
    },
    {
      id: 3,
      name: "Yellow Flame",
      image: HundredThousand,
      price: 20000,
      isSoldOut: false,
    },
    {
      id: 4,
      name: "Red Flame",
      image: TwoFiftyThousnad,
      price: 250000,
      isSoldOut: false,
    },
  ];

  let nairaCurrency = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
    currencyDisplay: "symbol",
  });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Firenation Inc | Home</title>
        <link rel="canonical" href="https://firenationinc.com" />
      </Helmet>
      <Layout>
        <div className="mt-20 w-full flex flex-col gap-8 justify-center items-center">
          <div className="w-full max-w-5xl flex items-center bg-black flex-col justify-center h-48">
            <motion.h2 className="font-bold text-5xl font-mono text-white capitalize text-center">
              Get your tickets now
            </motion.h2>
          </div>
          <div className="flex items-center justify-center flex-col">
            <motion.h2 className="font-bold text-2xl text-center">
              Buy Tickets
            </motion.h2>
            <div className="grid mt-6 md:grid-cols-2 grid-cols-1 w-full max-w-5xl gap-4">
              {data.map((d, index) => {
                return (
                  <div key={index} className="p-4 w-full">
                    <Item
                      id={d.id}
                      title={d.name}
                      price={nairaCurrency.format(d.price)}
                      image={d.image}
                      isSoldOut={d.isSoldOut}
                      classname={`${
                        index === 0
                          ? "bg-white text-black border border-[#DDDDDD]"
                          : index === 1
                          ? "bg-secondary text-black hover:text-white hover:bg-black"
                          : index === 2
                          ? "bg-[#53a606] text-white"
                          : index === 3
                          ? "bg-[#008ac0] text-white"
                          : index === 4
                          ? "bg-[#ea7ca5] text-white"
                          : "bg-black"
                      } `}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Home;
