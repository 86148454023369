import React from "react";

export const NavLinks = ({ open }) => {
  const links = [
    {
      link: "https://maps.app.goo.gl/mCZpURcbWa8Smh6M6?g_st=iw",
      label: "get directions",
    },
  ];

  return (
    <ul
      className={
        open
          ? `flex flex-col gap-y-8 p-8 h-auto  absolute top-[4rem] bg-black w-full left-0 lg:hidden`
          : `lg:flex-row gap-4 lg:flex hidden`
      }
    >
      {links.map((l, index) => (
        <li
          className="text-white tracking-[0.065rem] lg:text-black"
          key={index}
        >
          <a href={l.link} target="_blank" rel="noreferrer">
            {l.label}
          </a>
        </li>
      ))}
    </ul>
  );
};
