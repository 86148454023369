import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCart, updatePrice } from "../../app/slices/cart";

function Items({ id, title, price, image, isSoldOut = false, classname = "" }) {
  const { carts } = useSelector((state) => state.cart) || [];

  const getTotalCart = () => {
    let total = 0;
    carts &&
      carts?.length !== 0 &&
      carts?.forEach((item) => {
        total = item.quantity;
      });
    return total;
  };

  const dispatch = useDispatch();

  const dispatchFiltered = () => {
    const itemPrice = parseFloat(price.replace(/[₦,]/g, ""));

    if (getTotalCart() >= 1) {
      alert("You already have an item in cart");
    } else {
      dispatch(
        addCart({
          id,
          title,
          image,
          price,
          itemPrice,
        })
      );

      dispatch(updatePrice(itemPrice));
    }
  };

  // const originalPrice = price === "₦10,000.00" ? "₦10,000.00" : price;
  // const discountedPrice =
  //   price === "₦10,000.00"
  //     ? "₦7,000.00"
  //     : // : price === "₦20,000.00"
  //       // ? "₦17,000.00"
  //       // : price === "₦250,000.00"
  //       // ? "₦247,000.00"
  //       null;

  // const itemPrice =
  //   price === "₦10,000.00" ? 7000 : parseFloat(price.replace(/[₦,]/g, ""));

  return (
    <div className="bg-white relative shadow-xl flex w-full items-center justify-center flex-col gap-4">
      {/* {price === "₦10,000.00" && (
        <div className="w-fit fit absolute -top-5 right-0 bg-red-500 p-3">
          <p className="text-white">30% Discount</p>
        </div>
      )} */}
      {image ? (
        <img src={image} className="object-cover w-full h-full" alt="item" />
      ) : (
        <img src={image} className="object-cover w-full h-full" alt="item" />
      )}
      {isSoldOut === true && (
        <div className="absolute bg-red-500 -top-3 -left-3 rounded-lg px-2 py-1">
          <p className="text-white text-sm">sold out</p>
        </div>
      )}
      <div className="flex items-start justify-between w-full md:min-w-full p-4">
        <div className="flex flex-col">
          {/* {discountedPrice ? (
            <div className="flex items-center gap-3">
              <span className="line-through text-red-500">{originalPrice}</span>
              <span className="tracking-[0.065em]">{discountedPrice}</span>
            </div>
          ) : (
            <p className="tracking-[0.065em]">{price}</p>
          )} */}
          <p className="tracking-[0.065em]">{price}</p>
        </div>
        <button
          className={`${classname} flex gap-x-2 tracking-[0.065em] lowercase bg-black disabled:opacity-25 text-white py-2 px-4 rounded-md hover:bg-secondary`}
          onClick={() => dispatchFiltered()}
          disabled={isSoldOut === true}
        >
          Add to cart
        </button>
      </div>
    </div>
  );
}

export default Items;
